<template>
  <div>
    <v-autocomplete
      v-model="accountSelected"
      :items="accountList"
      item-text="cod_conta"
      item-value="value"
      outlined
      dense
      return-object
      :label="accountLabel"
      :readonly="readonly"
      :rules="rules"
      :loading="loading"
      :filter="filter"
      @change="saveAccount"
    >
      <template v-slot:selection="data">
        <template>
          (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
        </template>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.descricao }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedAccount: {
      type: Object,
      default: () => {},
    },
    accountLabel: {
      type: String,
      default: 'Conta'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      accountSelected: {},
      accountList: [],
      loading: true,
      isChipDialogOpen: false,
      createIccid: '',
    };
  },
  async mounted() {
    try {
      if (this.selectedAccount && Object.keys(this.selectedAccount).length > 0) {
        this.accountSelected = this.selectedAccount;
      }
      if (this.readonly) {
        this.accountList = [this.accountSelected];
      } else {
        await this.loadAccount();
        this.accountList = this.accounts;
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("account", ["accounts"]),
  },
  methods: {
    ...mapActions("account", ["loadAccount"]),

    filter (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return (
        regExp.test(item.cod_conta) || regExp.test('DDD - ' + item.ddd) || regExp.test(item.nome_operadora) || regExp.test(item.descricao)
      );
    },

    saveAccount () {
      this.$emit("saveAccount", this.accountSelected);
    }
  },
  watch: {
    selectedAccount(newVal) {
      if (this.accountSelected !== newVal) {
        this.accountSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
